<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "yangCan",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "使用前检查",
                        text:[
                            '#初始环境必须成功(图1)',
                        ],
                        img:[
                            "com/init.png",
                        ]
                    },
                    {
                        title: "功能说明",
                        text:[
                            "#切换到养蚕页",
                            "1.设置蚕架坐标图1,最大支持8个蚕架,注意:设置的坐标寻路后与蚕架的实际位置可能有偏差,助手只是扫描身边的蚕架,范围为图2" +
                            ",如果偏差太大,需要调整,否则会点不到蚕架或者点到旁边蚕架",
                            "2.每个坐标跑完一遍后视为完成一轮,一轮后可以卡时间,给蚕架使用蚕药和桑叶",
                        ],
                        img:[
                            "2.png",
                            "1.png",
                        ]
                    },
                    {
                        title: "游戏说明",
                        text:[
                            "#购买足够的蚕卵,蚕药,桑叶,打开背包后自动识别",
                            "#根据蚕架坐标填写好坐标后, 开始任务",
                        ],
                        img:[
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            this.$refs.row.initData(this.list);
        }
    }
</script>

<style scoped>

</style>
